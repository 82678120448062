<template>
  <div class="privacy-policy-page container">
    <h1 class="main-content_title">Privacy Policy</h1>
    <p class="line-style__1">
      HOLOWITS TECHNOLOGIES PTE. LTD. and its affiliates (collectively, "HOLOWITS", "we", "us", and "our") respect your
      privacy. Please read the following to learn more about our Privacy Policy ("this Policy"). This Policy applies to
      HOLOWITS websites, products, and services that display or provide links to this Policy.
    </p>
    <p class="line-style__1">
      This Policy describes how HOLOWITS processes your personal data, but it may not address all possible data
      processing scenarios. HOLOWITS may inform you of product- or service-specific data collection through
      supplementary policies or notices provided before collection.
    </p>
    <p class="line-style__1">
      This Policy describes:
    </p>
    <ul class="describes">
      <li>1. How We Collect & Use Your Personal Data</li>
      <li>2. Cookies & Similar Technologies</li>
      <li>3. How We Disclose Personal Data</li>
      <li>4. How to Access & Control Your Personal Data</li>
      <li>5. How We Protect Your Personal Data</li>
      <li>6. How We Process Children's Personal Data</li>
      <li>7. Third-Party Providers and Their Services</li>
      <li>8. International Transfers of Your Personal Data</li>
      <li>9. Updates to This Policy</li>
      <li>10. How to Contact Us</li>
    </ul>
    <h1 class="main-content-sub_title">
      1. How We Collect & Use Your Personal Data
    </h1>
    <p class="line-style__1">
      Personal data means any data that, either on its own or jointly with other data, can be used to identify a natural
      person. You directly provide us with such data when you use our websites, products, or services, or interact with
      us by, for example, creating a HOLOWITS account or contacting us for support. We may also obtain data by recording
      how you interact with our websites, products, or services. For example, we may use technologies like cookies or
      receive use data from software running on your device. As permitted by law, we may also obtain data from public
      and commercial third-party sources, for example, purchasing statistics from other companies to support our
      services. The personal data we collect includes name, gender, enterprise name, job position, postal and email
      addresses, phone number, login information (account and password), photos, and certificate information, etc.,
      depending on how you interact with HOLOWITS, for example, the website you visit or the products and services that
      you use. We also collect the information you provide to us and the content of messages you send us, such as the
      query information you provide, or the questions or information you provide for customer service support.
    </p>
    <p class="line-style__1">
      Before using HOLOWITS's products or services, you may need to provide personal data. In some cases you may be
      able to opt not to disclose your personal data to HOLOWITS. However, not providing HOLOWITS with certain data may
      mean that we cannot provide you with certain products or services or respond to an issue that you have raised.
    </p>
    <p class="line-style__1">
      We may use your personal data for the following purposes:
    </p>
    <ul class="purposes">
      <li>Creating an account.</li>
      <li>
        Fulfilling your transaction or service requests, including fulfilling orders; delivering, activating, or
        verifying products or services; providing training and certification; managing and processing training and
        certification exams; participating in onsite or virtual activities; fulfilling your requests for changes or
        providing you with the requested information (such as marketing materials for products and services, and white
        papers); and providing technical support.
      </li>
      <li>
        Contacting you with your consent; sending you information about products and services that may interest you;
        inviting you to participate in HOLOWITS activities (including promotional activities), market surveys, or
        satisfaction surveys; or sending you marketing information. If you do not want to receive these types of
        information, you can opt out at any time.
      </li>
      <li>Sending you important notices, such as installation of and updates to operating system or application.</li>
      <li>Providing you with customized user experience and content.</li>
      <li>Improving our products and services through internal audits, data analysis, and research.</li>
      <li>Analyzing the efficiency of our business operations and evaluating market share.</li>
      <li>Troubleshooting when you send us error reports.</li>
      <li>Synchronizing, sharing, and storing the data you upload or download and the data needed for the uploading and
        downloading.
      </li>
      <li>Ensuring the security of our products, services and customers or users, executing and improving our loss
        prevention and anti-fraud programs.
      </li>
      <li>Complying with and enforcing applicable legal requirements, industry standards and our policies.</li>
    </ul>
    <p class="line-style__1">
      HOLOWITS may also collect and use non-personally identifiable information (Non-PII). Non-PII is information that
      cannot be used to identify a particular individual. For example, HOLOWITS will collect statistical data, such as
      the numbers of visits to its website. We collect this data to understand how users use our websites, products, and
      services so that we can improve our services and better satisfy your needs. HOLOWITS may collect, use, process,
      transfer, or disclose non-PII for other purposes at its own discretion.
    </p>
    <p class="line-style__1">
      We will endeavor to isolate your personal data from non-PII and ensure that the two types of data are used
      separately. If personal data is combined with non-PII, it will still be treated as personal data during
      processing.
    </p>
    <p class="line-style__1">
      HOLOWITS will process your personal data following the requirements of applicable laws on an appropriate legal
      basis, including:
    </p>
    <ul class="purposes">
      <li>Processing your personal data to fulfill the contract when responding to a transaction or service request;
      </li>
      <li>Processing your personal data with your consent;</li>
      <li>Processing based on the legitimate interests of HOLOWITS or a third party when we use your personal data to
        contact you, conduct marketing or market surveys, improve our products and services, execute and improve our
        loss prevention and anti-fraud programs, and other purposes. Legitimate interests include enabling us to more
        effectively manage and operate our business and provide our products and services; protecting the security of
        our businesses, systems, products, services, and customers; internal management; complying with internal
        policies and processes; and other legitimate interests described in this policy;
      </li>
      <li>Processing your personal data as necessary to comply with and fulfill legal obligations.</li>
    </ul>
    <h1 class="main-content-sub_title">2. Cookies & Similar Technologies</h1>
    <h2 class="main-content-sub_title">2.1 Cookies</h2>
    <p class="line-style__1">
      To ensure our website works correctly, we may at times place a small piece of data known as a cookie on your
      computer or mobile device. A cookie is a text file stored by a web server on a computer or mobile device. The
      content of a cookie can be retrieved or read only by the server that creates the cookie. The text in a cookie
      often consists of identifiers, site names, and some numbers and characters. Cookies are unique to the browsers or
      mobile applications you use, and enable websites to store data such as your preferences or items in your shopping
      cart.
    </p>
    <p class="line-style__1">
      Like many other websites or Internet service providers, HOLOWITS uses cookies to improve user experience. Session
      cookies are deleted after each visit, while persistent cookies remain in place across multiple visits. Cookies
      allow websites to remember your settings such as language, font size on your computer or mobile device, or other
      browser preferences. This means that a user does not need to reset preferences for every visit. On the contrary,
      if cookies are not used, websites will treat you as a new visitor every time you load a web page. For example, if
      you are redirected to another web page from a website you are already logged in to and then return to the original
      website, it will not recognize you and you must log in again.
    </p>
    <p class="line-style__1">
      HOLOWITS will not use cookies for any purposes not stated in this policy. For further details on our use of
      cookies, please see our <span class="text_underline">Cookies Notice</span>. You can manage or delete cookies based
      on your own preferences. For
      details, visit <span class="text_underline">AboutCookies.org</span>. You can clear all the cookies stored on your
      computer, and most web browsers
      provide the option of blocking cookies. However, by doing so, you have to change the user settings every time you
      visit our website. Find out how to manage cookie settings for your browser here:
    </p>
    <p class="text_underline line_height-35">Internet Explorer</p>
    <p class="text_underline line_height-35">Google Chrome</p>
    <p class="text_underline line_height-35">Mozilla Firefox</p>
    <p class="text_underline line_height-35">Safari</p>
    <p class="text_underline line_height-35">Opera</p>
    <h2 class="main-content-sub_title">2.2 Web Beacons and Pixel Tags</h2>
    <p class="line-style__1">
      In addition to cookies, we may also use other similar technologies on our websites such as web beacons and pixel
      tags. For example, when you receive an email from HOLOWITS, it may contain a click-through URL that links to a
      HOLOWITS web page. If you click the link, HOLOWITS will track your visit to help us learn about your preferences
      for products and services and improve our customer service. A web beacon is a transparent graphic image embedded
      in a website or in an email. We use pixel tags in emails to find out whether an email has been opened. You can
      unsubscribe from the HOLOWITS mailing list at any time if you do not want to be tracked in this manner.
    </p>
    <p class="line-style__1">By using our website, you consent to the use of cookies, web beacons and pixel tags as
      described above.</p>
    <h1 class="main-content-sub_title">3. How We Disclose Personal Data</h1>
    <p class="line-style__1"><span class="">HOLOWITS shares your personal data with other partners, as described in this Policy, when services are provided by partners authorized by HOLOWITS. </span>
      For example, when you make an online purchase from HOLOWITS, we must share your personal data with the logistics
      provider to arrange shipment or a partner to provide services. In addition, as a global company, we may share
      personal data with HOLOWITS affiliates and subsidiaries.
    </p>
    <p class="line-style__1">
      To comply with applicable laws or respond to valid legal procedures, HOLOWITS may also disclose your personal data
      to law enforcement or other government agencies. If HOLOWITS is involved in a restructuring, merger & acquisition,
      or a bankruptcy or liquidation lawsuit in a given jurisdiction, your personal data may be disclosed in connection
      with the transaction. HOLOWITS may also disclose your data when appropriate, for example, to execute Terms and
      Conditions, when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or
      when it is in connection with an investigation of suspected or actual illegal activity.
    </p>
    <h1 class="main-content-sub_title">4. How to Access & Control Your Personal Data</h1>
    <p class="line-style__1">
      It is your responsibility to ensure that all personal data submitted to HOLOWITS is correct. HOLOWITS is dedicated
      to maintaining the accuracy and completeness of personal data and keeping the data up-to-date.
    </p>
    <p class="line-style__1">
      To the extent required by applicable law, you may (i) have the right to access certain personal data we maintain
      about you, (ii) request that we update or correct inaccuracies in that data, (iii) object or restrict to our use
      of your personal data, and (iv) ask us to delete your personal data from our database. To exercise these rights,
      please <a class="text_underline" href="/contactus" target="_blank">click here</a> to give your feedback online. Your written
      request may be required for security. We may decline
      the request if we have reasonable grounds to believe that the request is a fraudulent, unfeasible or may
      jeopardize the privacy of others.
    </p>
    <p class="line-style__1">
      If allowed by applicable laws, you have the right to withdraw your consent at any time when HOLOWITS processes
      your personal data based on your consent. However, withdrawal does not affect the legitimacy and effectiveness of
      how we process your personal data based on your consent before the withdrawal is made; nor does it affect any data
      processing based on another justification other than your consent.
    </p>
    <p class="line-style__1">
      If you think that the way we process your personal information does not comply with applicable data protection
      laws, you can contact the relevant competent data protection authority.
    </p>
    <h1 class="main-content-sub_title">5. How We Protect and Retain Your Personal Data</h1>
    <p class="line-style__1">
      The security of your personal data is important to us. We use appropriate physical, management, and technical
      measures to protect your personal data from unauthorized access, disclosure, use, modification, damage, or loss.
      For example, we use cryptographic technologies for data confidentiality, protection mechanisms to prevent attacks,
      and access control mechanisms to permit only authorized access to your personal data. We also provide training on
      security and privacy protection for employees to raise their awareness of personal data protection. HOLOWITS is
      committed to protecting your personal data; however, please note that no security measure is perfect.
    </p>
    <p class="line-style__1">
      We will retain your personal information for no longer than is necessary for the purposes stated in this Policy,
      unless otherwise extending the retention period is required or permitted by law. The data storage period may vary
      with scenario, product, and service. The standards HOLOWITS uses to determine the retention period are as follows:
      the time required to retain personal data to fulfill business purposes, including providing products and services;
      maintaining corresponding transaction and business records; controlling and improving the performance and quality
      of products and services; ensuring the security of systems, products, and services; handling possible user queries
      or complaints and locating problems; whether the user agrees to a longer retention period; and whether the laws,
      contracts, and other equivalencies have special requirements for data retention; etc. We will maintain your
      registration information as long as your account is necessary for service provision. You can choose to deregister
      your account. After you deregister your account, we will stop providing you with products and services through
      your account and delete your relevant personal data, provided that deletion is not otherwise stipulated by special
      legal requirements.
    </p>
    <h1 class="main-content-sub_title">6. How We Process Children's Personal Data</h1>
    <p class="line-style__1">
      Our websites, products and services are primarily intended for adults. A child must not create a HOLOWITS account
      without the consent of a parent or guardian. If a child's personal data is collected with prior parental consent,
      we will only use or disclose the data as permitted by law, with the explicit consent of the child's parents or
      guardians, or when necessary for the protection of the child. If we accidentally collect a child's personal data
      without verified prior consent from the child's parents, we will attempt to delete the data as soon as possible.
    </p>
    <h1 class="main-content-sub_title">7. Third-Party Providers and Their Services</h1>
    <p class="line-style__1">
      To ensure a positive user experience, you may receive content or web links from third parties other than HOLOWITS
      and its partners ("third parties"). HOLOWITS does not have the right to control such third parties, but you can
      choose whether to use the links, view the content and/or access the products or services provided by third
      parties.
    </p>
    <p class="line-style__1">
      HOLOWITS cannot control the privacy practices and data protection policies of third parties that are not subject
      to this Policy. When you submit personal information to such a third party, please read and refer to the privacy
      protection policy of the third party.
    </p>
    <h1 class="main-content-sub_title">8. International Transfers of Your Personal Data</h1>
    <p class="line-style__1">
      As a global company, your personal data collected by HOLOWITS may be processed or accessed in the country/region
      where you use our products and services or in other countries/regions where HOLOWITS or its affiliates,
      subsidiaries, service providers or business partners have a presence. These jurisdictions may have different data
      protection laws. In such circumstances, HOLOWITS will take measures to ensure that data is processed as required
      by this Policy and applicable laws, which includes when transferring the data subject’s personal data to a country
      or region , we may use a variety of legal mechanisms, such as signing standard contractual clauses , obtaining the
      consent to the cross-border transfer of a data , or implementing security measures like anonymizing personal data
      before cross-border data transfer.
    </p>
    <h1 class="main-content-sub_title">9. Updates to This Policy</h1>
    <p class="line-style__1">
      HOLOWITS reserves the right to update or change this Policy at any time. We will release the latest Privacy Policy
      on this page for any changes. If major changes are made to the Privacy Policy, we may notify you through different
      channels, for example, posting a notice on our website or sending you direct notification.
    </p>
    <h1 class="main-content-sub_title">10. How to Contact Us</h1>
    <p class="line-style__1">
      If you have any privacy complaints or issues, and want to contact HOLOWITS Data Protection Officer (DPO),
      please <a class="text_underline" href="/contactus" target="_blank">click here</a>.
      Where your personal data is processed by HOLOWITS in accordance with this Privacy Policy, the HOLOWITS entity that
      responds to your request, contacts you, provides you with products or services, or has signed or is about to sign
      a contract with you is the controller of the related personal data. You can <a class="text_underline" href="/contactus" target="_blank">click here</a> to obtain the contacts of
      HOLOWITS entities.
    </p>
    <p class="line-style__1 margin_bottom-50">Last updated: June 2022</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
}
</script>

<style scoped lang="less">

.privacy-policy-page {
  .main-content_title {
    text-align: center;
    margin-top: 30px;
  }

  .main-content-sub_title {
    margin-top: 30px;
  }

  .line-style__1 {
    font-size: 18px;
    line-height: 35px;
    margin-top: 20px;
  }

  .describes {
    font-size: 18px;
    line-height: 35px;
    text-indent: 1em;
  }

  .purposes {
    font-size: 18px;
    list-style: disc;
    line-height: 35px;
    margin-left: 40px;
  }

  .text_underline {
    font-size: 18px;
    text-decoration: underline;
  }

  .line_height-35 {
    line-height: 35px;
  }

  .text_yellow {
    background-color: #f3f304;
  }
  .margin_bottom-50{
    margin-bottom: 50px;
  }
}

</style>